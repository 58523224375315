//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectedItem from './SelectedItem.vue'

export default {
  name: 'InputSelect',
  components: {
    SelectedItem,
  },
  props: {
    selectedValues: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
}
