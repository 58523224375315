
































import { Vue, Component, Prop } from 'vue-property-decorator'
import { InvitableUser } from '@tada-team/tdproto-ts'
import { nodesStore, teamsStore } from '@/store'
import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import { TdCheckbox } from 'td-ui'

@Component({
  components: {
    BaseAvatar,
    TdCheckbox,
  },
})
export default class ItemUserCheckbox extends Vue {
  @Prop({
    type: InvitableUser,
    required: true,
  }) readonly user!: InvitableUser

  @Prop({
    type: Boolean,
    required: true,
  }) readonly external!: boolean

  @Prop({
    type: Array,
    required: true,
  }) readonly value!: InvitableUser[]

  get nodeName (): string {
    const nodes = nodesStore.state.nodes
    const n = nodes.find(n => n.uid === this.user.node)
    if (!n) throw new Error('Node no found')
    return n.title
  }

  get teamsNames (): string[] {
    const names: string[] = []
    const teamsIds = this.user.teams ?? []
    teamsIds.forEach(id => {
      const team = teamsStore.state.data[id]
      if (!team) return
      names.push(team.name)
    })
    return names
  }

  get caption (): string {
    return this.external
      ? this.nodeName
      : this.teamsNames.join(', ')
  }
}
