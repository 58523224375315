

























































































import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator'
import { teamsStore, groupsStore, contactsStore } from '@/store'
import UsersSelector from './UsersSelector/index.vue'
import GroupsSelector from './GroupsSelector/index.vue'
import { GroupError } from './types'
import { TdDivider, TdBtn } from 'td-ui'
import AddingContactsTable from './AddingContactsTable.vue'
import { Chat } from '@tada-team/tdproto-ts'

interface LoadingUser extends TADA.User {
  _success: boolean,
  _error: string | null,
}

@Component({
  components: {
    AddToGroupsErrors: () => import('./AddToGroupsErrors.vue'),
    UsersSelector,
    TdDivider,
    TdBtn,
    GroupsSelector,
    AddingContactsTable,
    IconArrowLeft: () => import('@tada/icons/dist/IconArrowLeftM.vue'),
  },
})
export default class TabOtherTeams extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: TADA.User[]
  @Prop({ type: Boolean, required: true }) readonly disabled!: boolean
  @Ref() readonly defaultGroupPicker!: GroupsSelector

  contactsError: string | null = null
  groupsGeneralErrors: string[] = []
  groupsSpecificErrors: GroupError[] = []
  selectedUsers: TADA.User[] = []
  successlyAddedContacts: TADA.Contact[] = []
  selectedGroups: Chat[] = []
  loadingUsers: LoadingUser[] | void[] = []
  stage: 'adding' | 'loading' | 'completed' = 'adding'
  stopUploadingFlag = false

  get hasErrors (): boolean {
    return (
      this.groupsSpecificErrors.length > 0 ||
      this.groupsGeneralErrors.length > 0
    )
  }

  get excludedTeamId (): string {
    return teamsStore.getters.currentTeam.uid
  }

  get commitButtonLabel (): string {
    const contactsCount = this.selectedUsers.length > 0 ? ` (${this.selectedUsers.length})` : ''
    return this.$t('modals.AddContact.inviteBtn') + contactsCount
  }

  handleUsersSelection (selectedUsers: TADA.User[]) {
    this.selectedUsers = [...selectedUsers]
  }

  @Watch('selectedUsers')
  handleUserSelect () {
    this.$emit('input', this.selectedUsers)
  }

  handleGroupSelection (selectedGroups: Chat[]) {
    this.selectedGroups = [...selectedGroups]
  }

  handleStopUploading () {
    this.stopUploadingFlag = true
    this.stage = 'adding'
  }

  /**
   * Main function that will attempt to:
   * add selected contacts to current team
   * and then add these to selected groups.
   */
  async commit () {
    // return: bool is needed to eliminate try/catch in parent component
    this.stage = 'loading'
    await this.addContactsToTeam()
    this.stage = 'completed'
    await this.addContactsToGroups(this.successlyAddedContacts)
  }

  /**
   * Attempts to add selected contacts to current team.
   * If one fails - all fail and promise rejects.
   * Handles and displays error.
   */
  async addContactsToTeam () {
    this.contactsError = null

    const teamId = teamsStore.state.currentTeamId

    this.loadingUsers = this.selectedUsers.map(user => {
      const loadingUser: LoadingUser = {
        _success: false,
        _error: null,
        ...user,
      }
      return loadingUser
    })

    for (let i = 0; i < this.loadingUsers.length; i++) {
      if (this.stopUploadingFlag) break
      try {
        let addedUser = null
        if (teamId) addedUser = await contactsStore.actions.addContacts([{ user_uid: this.loadingUsers[i].uid }])
        this.loadingUsers[i]._success = true
        this.successlyAddedContacts.push(addedUser[0])
      } catch (e) {
        this.loadingUsers[i]._error = e.error
        this.contactsError = e.error
      }
    }
  }

  /**
   * Adds provided contacts to selected groups. Makes API calls asynchronously.
   * Waits for all requests to resolve or reject.
   * Rejects if at least one request fails.
   * Resolved requests WILL add contacts to groups.
   * Only failed ones will not be added.
   */
  async addContactsToGroups (contacts: TADA.Contact[]) {
    // clear old errors
    this.groupsSpecificErrors = []
    this.groupsGeneralErrors = []

    // get selected groups from a child component
    const groups = this.selectedGroups.map((group: Chat) => group.jid)

    // try asynchronously adding all contacts to all groups
    // wait for all such requests to settle
    const settledPromises = await Promise.allSettled(
      contacts.map(contact => this.addContactToGroups(contact, groups)),
    )

    // detect if any contacts could not be added to any groups and reject
    if (settledPromises.some(p => p.status === 'rejected')) {
      return Promise.reject(new Error('Could not add contact to groups.'))
    }
  }

  /**
   * Asynchronously attempts to add provided contact to provided groups.
   * Returs an array of promises with such asynchronous attempts.
   */
  addContactToGroups (contact: TADA.Contact, groups: string[]) {
    const { jid: memberId } = contact
    return groups.map(async groupId => {
      const payload = { groupId, memberId }
      return groupsStore.actions.addMember(payload).catch(reason => {
        this.handleGroupError(reason, contact, groupId)
        return Promise.reject(reason)
      })
    })
  }

  /**
   * Handles server error returned for a failed add contact to group attempt.
   * Don't worry, Sentry has probably already reported this.
   */
  handleGroupError (reason: any, contact: TADA.Contact, groupId: string) {
    const { details } = reason

    if (details) { // if error details were returned by server
      const { jid } = details
      if (jid) { // if the error was in user jid provided by client
        const who = contact.displayName
        const what = jid
        this.groupsSpecificErrors.push({ who, what })
      } else { // error is probably in client data, but unspecified
        this.groupsGeneralErrors.push(Object.values(details).join(' '))
      }
    } else { // something went terribly wrong
      // at least try to tell to the user where it happened
      this.groupsSpecificErrors.push({
        who: groupsStore.state.data[groupId]?.displayName ?? groupId,
        what: reason.error ?? this.$t('errors.implicitError'),
      })
    }
  }
}
