































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getDefaultFuseOptions } from '@/utils'
import ItemUserCheckbox from './ItemUserCheckbox.vue'
import ItemNoResults from './ItemNoResults.vue'
import InputSelect from './InputUserSelect.vue'
import Fuse from 'fuse.js'
import { TdInput } from 'td-ui'
import { invitableUsersStore } from '@/store'
import { InvitableUser } from '@tada-team/tdproto-ts'
import { multiSearch } from '@/utils/Common'

@Component({
  components: {
    ItemUserCheckbox,
    ItemNoResults,
    TdInput,
    InputSelect,
    IconInfo: () => import('@tada/icons/dist/IconInfoM.vue'),
  },
})
export default class UsersSelector extends Vue {
   @Prop({
     type: Array,
     required: true,
   }) readonly value!: []

   @Prop({
     type: Boolean,
     default: false,
   }) readonly externalUsers!: boolean

   @Prop({
     type: String,
     default: '',
   }) readonly excludeTeam!: string

  private loading = false
  private users: InvitableUser[] = []
  private options: InvitableUser[] = []
  private loadUsersError = false
  private fuseOptions = getDefaultFuseOptions(['displayName'])
  private filterValue = ''
  private selectedUsers = []

  created () {
    this.setupUsers()
    this.selectedUsers = [...this.value]
  }

  @Watch('selectedUsers')
  handleUserSelect () {
    this.$emit('user-select', this.selectedUsers)
    this.filterValue = ''
  }

  private get fuseForContacts (): Fuse<InvitableUser> {
    return new Fuse(this.users, this.fuseOptions)
  }

  private async setupUsers (): Promise<void> {
    this.loading = true
    this.loadUsersError = false

    try {
      this.externalUsers
        ? await invitableUsersStore.actions.loadAllExternal(this.excludeTeam)
        : await invitableUsersStore.actions.loadAllInternal(this.excludeTeam)
    } catch (e) {
      this.loadUsersError = true
      this.loading = false
      return
    }

    if (this.externalUsers) {
      this.users = invitableUsersStore.getters.externalSorted
      this.options = invitableUsersStore.getters.externalSorted
    } else {
      this.users = invitableUsersStore.getters.internalSorted
      this.options = invitableUsersStore.getters.internalSorted
    }

    this.loading = false
  }

  private contactSearch (text: string): InvitableUser[] {
    const searchFn = (text: string) => this.fuseForContacts.search(text)
    return multiSearch(searchFn, text, 'uid')
  }

  private filterFn () {
    const val = this.filterValue.trim().toLowerCase()

    this.options = val.length < 1
      ? this.users
      : this.contactSearch(val)
  }

  private handleUserRemove (removedUserId: string) {
    this.selectedUsers = this.selectedUsers.filter((user: InvitableUser) => user.uid !== removedUserId)
  }
}
